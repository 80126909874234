@import '/node_modules/animate.css/animate.css';




.container {
    min-width: calc(100% - 6px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0%;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
}

.header {
    text-align: center;
    width: 100%;
    height: 2rem;
    margin-top: 0;
    position: fixed;
    z-index: 1000;
    background-color: var(--bgColor);
    
}

.catContainer{
    width: 100%;
    margin-top: 2rem;
    margin-left: 5px;
    margin-right: 5px;
}


.catBlock {
    /* margin-top: 2rem; */
    margin-bottom: 1rem;
    background: var(--tg-theme-secondary-bg-color);
    z-index: 100;
    border-radius: 5px;
    text-align: center;
    width: 100%;
}





.numberField {

    display: flex;
    flex-direction: column;
    width: 100%;
    /* border-radius: 5px; */
    border: none;
    z-index: 100;
    /* transition: all 0.2s ease-in-out; */
}

.numberField.inputtype.hide {

    max-height: 0;
    flex-direction: column;
    /* border-radius: 5px; */
    border: none;
    z-index: 100;
    /* transition: all 0.2s ease-in-out, */
}



.productName {
    position: relative;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    color: var(--tg-theme-section-header-text-color);
  
    justify-content: center;
    margin-bottom: 0.5rem;
    border: none;
}


.inputLine {
    /* position: relative; */
    display: flex;
    max-height: fit-content;
    width: 100%;
    flex-direction: row;
    margin: auto;
    /* padding: auto; */
    z-index: inherit;
    /* transition: all 0.5s ease-in-out; */

}





.inputAndIncDec {
    background-color: var(--tg-theme-secondary-bg-color);
    display: flex;
    flex-direction: row;
    border: 1px solid DimGray;
    margin: 5px;
    /* transition: all 0.5s ease-in-out; */

}

.inputAndIncDec.hide {
    background-color: var(--tg-theme-secondary-bg-color);
    display: flex;
    flex-direction: row;
    border: 1px solid DimGray;
    /* margin: 5px; */
    /* transition: all 0.2s ease-in-out; */
}

.inputLine2 {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    margin: auto;
    padding: auto;
}

.reactAriaButton {
    background-color: var(--tg-theme-secondary-bg-color);
    border: none;
    /* border-radius: 5px; */
    display: flex;
    flex-direction: row;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1rem;
    outline: none;
    /* transition: all 0.2s ease-in-out; */
   
    
}

.reactAriaButton.hide {
    background-color: var(--tg-theme-secondary-bg-color);
    border: none;
    /* border-radius: 5px; */
    display: flex;
    flex-direction: row;
    width: 2rem;
    height: 0;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1rem;
    outline: none;
    /* transition: all 0.2s ease-in-out; */
}

.input {
    border: none;
    background-color: var(--tg-theme-secondary-bg-color);
    width: calc(100% - 4rem - 2px);
    font-size: 1rem;
    text-align: center;
    outline: none;
    /* transition: all 0.2s ease-in-out; */

}

.input.hide {
    border: none;
    background-color: var(--tg-theme-secondary-bg-color);
    height: 0;
    width: calc(100% - 4rem - 2px);
    font-size: 1rem;
    text-align: center;
    outline: none;
    /* transition: all 0.2s ease-in-out; */

}



.inputtype {
    font-size: 0.6rem;
    color: var(--tg-theme-section-header-text-color);
    position: relative;
    display: inline;
    width: fit-content;
    flex-direction: row;
    background-color: var(--tg-theme-secondary-bg-color);
    left: 50%;
    transform: translateX(-50%);
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 0px;
    margin-bottom: -0.65rem;
    z-index: 100;
    /* transition: all 0.2s ease-in-out; */
}

.inputtype.hide {
    font-size: 0.6rem;
    color: var(--tg-theme-section-header-text-color);
    position: relative;
    display: inline;
    width: fit-content;
    height: 0;
    flex-direction: row;
    background-color: var(--tg-theme-secondary-bg-color);
    left: 50%;
    transform: translateX(-50%);
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 0px;
    margin-bottom: -0.65rem;
    z-index: 100;
    /* transition: all 0.5s ease-in-out; */
}



.inputAndIncDec:focus-within {
    border-color: var(--focus-ring-color);
    border-width: 2px;
    height: auto;
    outline: none;

}

.input:focus {
    /* top: -0.5rem; */
    /* box-sizing: content-box; */
    border: none;
    outline: none;
    background-color: var(--tg-theme-secondary-bg-color);
    font-size: 1rem;
}

.expandButton.down {
    background-color: var(--tg-theme-secondary-bg-color);
    fill: var(--tg-theme-section-header-text-color);
    border: none;
    display: content-box;
    flex-direction: column;
    position: relative;
    /* transition: all 0.7s ease-in-out; */
    height: 5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 30px;
    margin-bottom: 0;

}

.expandButton.up {
    background-color: var(--tg-theme-secondary-bg-color);
    fill: var(--tg-theme-section-header-text-color);
    border: none;
    display: content-box;
    flex-direction: column;
    position: relative;
    transition: all 0.7s ease-in-out;
    height: 5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 30px;
}

.submit {
    align-items: center;
    display: flex;
    left: 1px;
    justify-content: center;
    width: 100%;
    /* margin: 5px; */
    /* padding: 10px 20px; */
    position: relative;
    z-index: 1000;
    bottom: 0;
    /* margin-right: 3px;
    margin-left: 3px; */
    border-radius: 5px;
    font-size: 1rem;
    border: none;
    outline: none;
    color: var(--tg-theme-button-text-color);
    height: 2.4rem;
    background: var(--tg-theme-button-color);

}

.mainExpButtContainer{
    position: absolute;
        right: 75px;
        top: 10px;
    max-width: 10px;
    height: 30px;
    /* padding-bottom: -10px; */
    margin: 0;
    /* z-index: 0; */
}

.mainExpandButtonDown{
    background-color: var(--bgColor);
    fill: var(--tg-theme-section-header-text-color);
    border: none;
    display: flex;
    /* flex-direction: column; */
    position: absolute;
    transition: all 0.7s ease-in-out;
    height: 30px;
    z-index: 100;
    
    

}

.mainExpandButtonDown2{
    background-color: var(--bgColor);
    fill: var(--tg-theme-section-header-text-color);
    border: none;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all 0.7s ease-in-out;
    margin-bottom: 0px;
    height: 30px;
    z-index: 10;
}

.broom{
    position: absolute;
        right: 60px;
        top: -25px;
    width: 20px;
    /* height: 20px; */
    fill: var(--tg-theme-section-header-text-color);
    z-index: 1000;
    
}