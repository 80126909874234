.container {
    top: 0;
    background: var(--bgColor);
    display: flex;
    flex-direction: column;
}



.date {
    text-align: center;
}

.caBlock {
    background-color: var(--tg-theme-secondary-bg-color);
    margin-bottom: 5px;
    flex-direction: row;
    border-radius: 5px;
}

.ca {
    margin-top: 10;
    text-align: center;
}

.manager {
    font-size: 0.5rem;
    margin-left: 2px;
}

.nomenclatureBlock {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    margin-left: 2px;
    margin-right: 2px;
    height: fit-content;
    font-size: 1rem;
    opacity: 1;
    visibility: visible;
    /* transition: height 0.5s ease; */
}

.nomenclatureRow {
    font-size: 0.7rem;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid;
    --row-height: 100%;
    height: var(--row-height);
}

.no {
    text-align: center;
    width: 1rem;
    margin: auto;
}

.amt {
    flex: 1;
    width: 2rem;
    text-align: center;
    margin: auto;
}

.nomenclature {
    border-left: 1px solid;
    border-right: 1px solid;
    width: calc(100% - 3rem);
}

.nomenclatureBlock.hide {
    opacity: 0;
    visibility: hidden;
    height: 0;
    /* transition:  height 0.5s ease; */
}

.expand {
    display: flex;
    position: relative;
    font-size: 0.7rem;
    color: blue;
    text-decoration: underline;
    justify-content: right;
    margin-top: 3px;
    z-index: 1000;
    overflow: hidden;
    outline: 2px solid var(--tg-theme-secondary-bg-color);
}

.progress{
    display: flex;
    justify-content: center;
    margin-top: 45vh;
    
  }