.header {
  /* background: var(--tg-theme-secondary-bg-color); */
  /* background: black; */
  /* display: flexbox; */

  /* margin-bottom: 15px; */
  font-size: 0.7rem;
  height: 1rem;
  left: 0px;
  right: 0px;
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  position: fixed;
  /* Фиксирует заголовок сверху */
  top: 0;
  border-radius: 5px;
  /* Привязывает к верхней части окна */
  /* width: 100%; */
  /* margin: 3px; */
  margin-right: 3px;
  margin-left: 3px;
  /* padding: 3px; */
  /* background-color: var(--tg-theme-header-bg-color); */
  background-color: var(--bgColor);

  /* bottom: 70rem; */
  z-index: 1000;
  /* Обеспечивает, что заголовок будет поверх других элементов */
}

.container {
  top: 0;
  width: 100vw;
  height: 100vh;
 display: flex;
    background: var(--bgColor);
  flex-direction: column;
  align-items: center;
  
  /* justify-content: center; */
 
}

.subheader {
  position: relative;
  height: auto;
  color: var(--tg-theme-text-color);
  background-color: var(--bgColor);
  /* background-color: none; */
  border-radius: 5px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

.formContainer {
  
  /* top: 5px; */
  /* margin-top: 1rem; */
  display: flex;
  right: 0;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 3px;
  margin: 0px;
  border-radius: 10px;
  text-align: center;
  /* justify-content: center; */
  /* margin-bottom: 2.5rem; */
  outline: none;
  z-index: 100;
}

.label{
  /* left: 0; */
  width: 4rem;
  position: relative;
  /* margin-left: 0; */
  /* margin-right: 1%; */
  /* padding-left: 1px; */
  /* text-align: left; */
  display: flex;
  /* flex-direction: row; */
  color: var(--tg-theme-text-color);
  align-items: center;
  text-align: left;
  border: none;
  z-index: 100;
}

.group {
  outline: none;
  border-radius: 5px;
  /* background-color: var(--tg-theme-section-bg-color); */
  background-color: var(--tg-theme-secondary-bg-color);
  /* top: 1rem; */
  height: fit-content;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* box-sizing: border-box; */
  width: 100%;
  /* padding: 0; */
  /* box-sizing: border-box; */
  /* flex-grow: 1; */
  position: relative;
  /* overflow-y: scroll; */
  /* bottom: auto; */
  /* border-color: var(--tg-theme-section-separator-color); */
  /* border-color: black; */
  /* outline: auto; */

  /* padding: 2px; */
  
  /* margin-top: 0; */
  /* margin-bottom: 0px; */
  /* border: none; */
  text-align: center;
  /* align-items: flex-start; */
  /* justify-items: center; */


}

.inputLine {
  display: inline-flex;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  flex-direction: row;
  border: 1px solid DimGray;
  /* outline: 0.5px solid; */
  
  z-index: 100
}

.inputLineWithLabel{
  display: flex;
  flex-direction: row;
}

.inputs {
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  bottom: 1px;
  /* top: 0; */
  position: relative;
  text-align: center;
  /* background: var(--tg-theme-bg-color); */
  background-color: var(--tg-theme-secondary-bg-color);
  /* border: 1px solid var(--border-color); */
  height: fit-content;
  color: var(--tg-theme-section-header-text-color);
  /* margin: 0 -1px; */
  border: none;
  border-radius: 5px;
  z-index: 1;
  font-size: 1rem;
  /* padding: 0.429rem 0.571rem; */
  padding: 0.5rem 0.5rem;
  outline: none;
  width: 100%;
  margin-bottom: 0px;
  /* flex: initial; */


}

.numberField {
  display: flow-root;
  flex-direction: row;
  top: 0rem;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  width: 100%;
  bottom: 0;
  padding: 0;
  outline: none;
  border: none
}

.reactAriaButton {
  border: 0;
  outline: 0;
  background: black;
  font-size: 1.4rem;
  width: 2.5rem;
  padding: 0;
}

.reactAriaButton[slot=decrement] {
  text-align: center;
  background: var(--tg-theme-secondary-bg-color);
  border: none;
  color: var(--tg-theme-text-color);
  width: 2.5rem;
  outline: none;
}

.reactAriaButton[slot=increment] {
  border: 0;
  text-align: center;
  background: var(--tg-theme-secondary-bg-color);
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  color: var(--tg-theme-text-color);
  width: 2.5rem
}

.description {
  z-index: 100;
  color: var(--tg-theme-section-header-text-color);
  position: absolute;
  font-size: 0.7rem;
  text-align: center;
  left: 50%;
  box-sizing: content-box;
  transform: translateX(-50%);
  top: 10px;
  transition: all 0.2s ease;
  padding: 0 5px;
  pointer-events: none;
}

.numberField input:focus+.description,
.numberField input:valid+.description {
  top: -0.5rem;
  box-sizing: content-box;
  background-color: var(--tg-theme-secondary-bg-color);
  font-size: 0.7rem;
  
  /* Уменьшаем размер шрифта */
  color: #007bff;
  /* Цвет текста при фокусе */
}

.inputLine focus-within.description {
  top: -0.5rem;
  box-sizing: content-box;
  background-color: var(--tg-theme-secondary-bg-color);
  font-size: 2rem;
  font-weight: bold;
  /* Уменьшаем размер шрифта */
  color: #007bff;
  /* Цвет текста при фокусе */
}

.input {
  height: 2rem;
  display: flex;
  position: relative;
  flex-direction: row;
  bottom: 0;
  text-align: center;
  background-color: var(--tg-theme-secondary-bg-color);
  border: none;
  color: var(--tg-theme-text-color);
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  outline: none;
  width: 100%;
  flex: 1;

}



.inputLine:focus-within{
  border-color: var(--focus-ring-color);
  border-width: 2px;
}



.submit {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  min-width: calc(100% - 6px);
  padding: 10px 20px;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  /* text-align: center; */
  /* margin: auto; */
  /* margin-top: 0.1rem; */
  /* margin-left: 5px; */
  margin-right: 3px;
  margin-left: 3px;
  border-radius: 5px;
  font-size: 1rem;
  border: none;
  outline: none;
  color: var(--tg-theme-button-text-color);
  height: 2.4rem;
  background: var(--tg-theme-button-color);

}