.list {
    /* cursor: pointer; */
    /* position: absolute; */
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: 100%; */
    /* text-align: left; */
    /* margin-left: 1px; */
    /* width: 100%; */
    background-color: var(--bgColor);
    max-width: calc(100% - 2px);

}

.plan {
    display: flex;
    font-weight: bold;
    top: 30px;
    margin-bottom: 10px;
    margin-left: 1px;
    margin-right: 1px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    white-space: pre-line;
    /* text-indent: 5em hanging each-line; */
    color: var(--tg-theme-section-header-text-color);
    text-align: left;
    justify-content: center;
    font-size: 0.8rem;
    border: 2px solid;
    width: calc(100% - 2px);
    background-color: var(--tg-theme-section-bg-color);
    border-radius: 5px;
    border-color: var(--tg-theme-button-color);
    z-index: 0;
}

.planText{
    /* color: black; */
    margin-left: 0%;
    text-indent: 1px each-line;
    /* white-space: pre-line; */
}

.item {
    /* margin-right: 10px; */
    margin-bottom: 10px;
    /* justify-content: stretch; */
    /* background: var(--tg-theme-bg-color) */
}

.btn {
   
    border: none;
    border-radius: 5px;
    text-decoration: none;
    /* color: white;
    background: #0B63F6;    */
    /* box-shadow: 0 5px 0 #003CC5; */
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: center;
    text-align: center;
    min-width: calc(100% - 2px);
    margin-left: 1px;
    margin-right: 1px;
    height: 2.4rem;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
}

.sent {

    background-color: lightgray;
    border-radius: 5px;
    color: solid;
    font-size: 1rem;
    display: block;
    position: fixed;
    margin: auto;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
    z-index: 100;
    /* top: 45px; */
    align-items: center;

    width: auto;
    height: auto;
    animation: fadeOut; 
    animation-duration: 4s 
    
    

}