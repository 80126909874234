@import "./theme.css";

.react-aria-Button {
  color: var(--text-color);
  background: var(--button-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  appearance: none;
  vertical-align: middle;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  outline: none;
  padding: 6px 10px;
  text-decoration: none;

  &[data-pressed] {
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 0.1);
    background: var(--button-background-pressed);
    border-color: var(--border-color-pressed);
  }

  &[data-focus-visible] {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: -1px;
  }

  &[data-disabled]{
    border-color: var(--border-color-disabled);
    color: var(--text-color-disabled);
  }
}
