.header {
  /* background: var(--tg-theme-secondary-bg-color); */
  /* background: black; */
  /* display: flexbox; */

  /* margin-bottom: 15px; */
  flex: 0 0 auto;
  font-size: 0.7rem;
  height: 1rem;
  left: 0px;
  right: 0px;
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  position: fixed;
  /* Фиксирует заголовок сверху */
  top: 0;
  border-radius: 5px;
  /* Привязывает к верхней части окна */
  /* width: 100%; */
  /* margin: 3px; */
  margin-right: 3px;
  margin-left: 3px;
  /* padding: 3px; */
  /* background-color: var(--tg-theme-header-bg-color); */
  background-color: var(--bgColor);

  /* bottom: 70rem; */
  z-index: 1000;
  /* Обеспечивает, что заголовок будет поверх других элементов */
}

.container {
  display: flex;
  background: var(--bgColor);
  top: 0;
  width: 100%;
  height: fit-content;
  /* left: 0;
    top: 0; */
  flex-direction: column;
  /* background-color: var(--tg-theme-secondary-bg-color); */
  /* background-color: white; */
}

.subheader {
  position: relative;
  height: auto;
  background: var(--tg-theme-secondary-bg-color);
  /* background-color: none; */
  border-radius: 5px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

.formContainer {
  flex: 1 1 auto;
  overflow-y: auto;
  /* top: 5px; */
  /* margin-top: 1rem; */
  display: flex;
  right: 0;
  /* flex-direction: column; */
  /* width: 100%; */
  /* position: relative; */
  /* padding: 3px; */
  /* margin: 0px; */
  border-radius: 10px;
  text-align: center;
  /* margin-bottom: auto; */
  outline: none;
  /* z-index: 100; */

}

.group {
  display: flex;
  flex: 1 1 auto;
  outline: none;
  border-radius: 5px;
  /* background-color: var(--tg-theme-section-bg-color); */
  background-color: var(--tg-theme-secondary-bg-color);
  /* top: 1rem; */
  /* height: fit-content; */
  justify-content: space-between;
  
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* box-sizing: border-box; */
  width: 100%;
  padding: 0;
  /* box-sizing: border-box; */
  /* flex-grow: 1; */
  position: relative;
  /* overflow-y: scroll; */
  bottom: var(--totCoffeHeight);
  /* border-color: var(--tg-theme-section-separator-color); */
  /* border-color: black; */
  /* outline: auto; */

  /* padding: 2px; */
  margin: auto;
  margin-top: 1rem;
  height: auto;
  /* border: none; */
  text-align: center;
  /* align-items: flex-start; */
  /* justify-items: center; */


}

.inputLine {
  display: inline-flex;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  flex-direction: row;
  border: 1px solid DimGray;
  z-index: 100
}

.inputs {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  left: 0;
  right: 0;
  text-align: center;
  background-color: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-text-color);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
}

.numberField {
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding: 0;
  outline: none;
  border: none
}

.reactAriaButton {
  border: 0;
  outline: 0;
  background: black;
  font-size: 1.4rem;
  width: 2.5rem;
  padding: 0;
}

.reactAriaButton[slot=decrement] {
  text-align: center;
  background: var(--tg-theme-secondary-bg-color);
  border: none;
  color: var(--tg-theme-text-color);
  width: 2.5rem;
  outline: none;
}

.reactAriaButton[slot=increment] {
  border: 0;
  text-align: center;
  background: var(--tg-theme-secondary-bg-color);
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  color: var(--tg-theme-text-color);
  width: 2.5rem
}

.description {
  z-index: 100;
  color: var(--tg-theme-section-header-text-color);
  position: absolute;
  font-size: 0.5rem;
  text-align: center;
  left: 50%;
  box-sizing: content-box;
  transform: translateX(-50%);
  top: 10px;
  transition: all 0.2s ease;
  padding: 0 3px;
  pointer-events: none;
}

.numberField input:focus+.description,
.numberField input:valid+.description {
  top: -0.4rem;
  box-sizing: content-box;
  background-color: var(--tg-theme-secondary-bg-color);
  font-size: 0.5rem;
  color: #007bff;
}

.inputLine focus-within.description {
  top: -0.5rem;
  box-sizing: content-box;
  background-color: var(--tg-theme-secondary-bg-color);
  font-size: 2rem;
  font-weight: bold;
  /* Уменьшаем размер шрифта */
  color: #007bff;
  /* Цвет текста при фокусе */
}

.input {
  height: 2rem;
  display: flex;
  position: relative;
  flex-direction: row;
  bottom: 0;
  text-align: center;
  background-color: var(--tg-theme-secondary-bg-color);
  border: none;
  color: var(--tg-theme-text-color);
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  outline: none;
  width: 100%;
  flex: 1;
  

}

.inputtype {
  font-size: 0.7rem;
  color: var(--tg-theme-section-header-text-color);
  position: relative;
  display: flex;
  width: fit-content;
  flex-direction: column;
  background-color: var(--tg-theme-secondary-bg-color);
  left: 50%;
  transform: translateX(-50%);
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 0px;
  /* margin-bottom: -0.65rem; */
  z-index: 100;
  /* transition: all 0.2s ease-in-out; */
}



.inputLine:focus-within{
  border-color: var(--focus-ring-color);
  border-width: 2px;
}

.totcoffe{
  margin-top: auto;
  /* margin-bottom: 2.5rem; */
  display: flex;
  outline: none;
  font-size: 0.7rem;
  /* --height1: auto; */
  /* position: fixed; */
  justify-content: center;
  text-align: center;
  width: 100%;
  /* margin-bottom: 1px; */
  /* margin-top: 1px; */
  /* padding: 2px; */
  /* top: 1px; */
  /* bottom: 2.4rem; */
  /* z-index: 100; */
  color: var(--tg-theme-text-color);
  /* z-index: 1000; */
}


.submit {
  display: flex;
  /* flex: 0 0 auto; */
  align-items: center;
  justify-content: center;
  /* min-width: calc(100% - 6px); */
  /* width: 100%; */
  height: 100%;
  /* padding-left: 50px; */
  /* padding: 10px 20px; */
  position: relative;
  z-index: 1000;
  bottom: 0;
  /* margin: auto; */
  /* margin-top: 0.1rem; */
  /* margin-left: 5px; */
  /* margin-right: 3px;
  margin-left: 3px; */
  border-radius: 5px;
  font-size: 1rem;
  border: none;
  outline: none;
  color: var(--tg-theme-button-text-color);
  height: 2.4rem;
  background: var(--tg-theme-button-color);

}